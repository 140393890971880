import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Text, Container, Col, Row, Button } from "atomize"
import { Helmet } from "react-helmet"
import "../css/global.css"
function EmbedTest() {
  return (
    <Layout>
      <SEO
        title="Dein Corona Navi"
        description="Deine zentrale Anlaufstelle für alle Themen rund um Corona."
      />
      <Helmet>
        <link
          href="https://static.convaise.com/corona-navi/corona-navi-popup-v1-min.css"
          rel="stylesheet"
        />
      </Helmet>
      <Container p={{ t: "8rem" }}>
        <Row>
          <Col size={{ xs: 12, md: 8 }}>
            <Text
              textSize={{ xs: "title", sm: "heading", md: "display1" }}
              textWeight="500"
              fontFamily="secondary"
              m={{ b: "1rem" }}
            >
              Iframe Test
            </Text>
            <iframe
              title="testIframe"
              src="/embed"
              width="600"
              height="800"
              style={{
                minHeight: "450px",
                minWidth: "300px",
                borderWidth: "0px",
                margin: "1px",
              }}
            ></iframe>
          </Col>
          <Col size={{ xs: 12, md: 4 }}>
            <Button
              rounded="circle"
              shadow="1"
              hoverShadow="4"
              // disabled={() => {
              //   var chat = document.getElementById("convaise-chat")
              //   if (chat.style.display === "none") {
              //     return false
              //   }
              //   return true
              // }}
              w={{ xs: "100%", sm: "100%" }}
              p={{ x: "1.5rem" }}
              justify="space-between"
              bg="black700"
              hoverBg="black500"
              onClick={() => {
                var wrapper = document.getElementById("convaise-wrapper")
                if (wrapper) wrapper.style.display = "block"
              }}
            >
              Popup wieder einblenden
            </Button>
            <div id="convaise-wrapper">
              <div className="convaisecss-clear">
                <div id="convaise-chat" className="convaisecss-chat-content">
                  <div className="convaisecss-chat-header">
                    <nav className="convaisecss-chat-header__navbar convaisecss-navbar">
                      <a className="convaisecss-auto convaisecss-navbar-brand">
                        <img
                          alt="Avatar"
                          loading="lazy"
                          src="https://www.corona-navi.de/avatar-background.svg"
                          className="convaisecss-start-bttn--logo-img"
                        />
                        Corona Informationsassistent
                      </a>
                      <button
                        type="button"
                        aria-label="Chat schließen"
                        id="closeConvaiseChat"
                        className="convaiscss-bttn convaisecss-chat--close-bttn"
                      >
                        <svg
                          fill="currentColor"
                          preserveAspectRatio="xMidYMid meet"
                          height="22"
                          width="22"
                          viewBox="0 0 40 40"
                          style={{ verticalAlign: "middle" }}
                        >
                          <g>
                            <path d="m33.5 29.5q0 0.9-0.7 1.5l-3 3.1q-0.6 0.6-1.5 0.6t-1.5-0.6l-6.6-6.6-6.5 6.6q-0.7 0.6-1.6 0.6t-1.5-0.6l-3-3.1q-0.6-0.6-0.6-1.5t0.6-1.5l6.5-6.6-6.5-6.5q-0.6-0.7-0.6-1.6t0.6-1.5l3-3q0.6-0.6 1.5-0.6t1.6 0.6l6.5 6.6 6.6-6.6q0.6-0.6 1.5-0.6t1.5 0.6l3.1 3q0.6 0.7 0.6 1.5t-0.6 1.6l-6.6 6.5 6.6 6.6q0.6 0.6 0.6 1.5z"></path>
                          </g>
                        </svg>
                      </button>
                    </nav>
                  </div>
                  <div className="convaisecss-chat-body">
                    <iframe
                      src="/embed"
                      width="100%"
                      height="100%"
                      title="Chat"
                    ></iframe>
                  </div>
                </div>
                <div id="convaise-bttns">
                  <button
                    type="button"
                    aria-label="Chat öffnen"
                    id="openConvaiseChat"
                    className="convaiscss-bttn"
                  >
                    <div className="convaisecss-start-bttn--text">
                      <img
                        alt="Corona Navi"
                        loading="lazy"
                        src="https://www.corona-navi.de/avatar-background.svg"
                        className="convaisecss-start-bttn--logo-img"
                      />
                      Corona Navi
                    </div>
                  </button>
                  <button
                    type="button"
                    aria-label="Chat entfernen"
                    id="removeConvaiseChat"
                    className="convaiscss-bttn convaisecss-remove-bttn"
                  >
                    <svg
                      fill="currentColor"
                      preserveAspectRatio="xMidYMid meet"
                      height="22"
                      width="22"
                      viewBox="0 0 40 40"
                      // style={{ verticalAlign: "middle" }}
                    >
                      <g>
                        <path d="m33.5 29.5q0 0.9-0.7 1.5l-3 3.1q-0.6 0.6-1.5 0.6t-1.5-0.6l-6.6-6.6-6.5 6.6q-0.7 0.6-1.6 0.6t-1.5-0.6l-3-3.1q-0.6-0.6-0.6-1.5t0.6-1.5l6.5-6.6-6.5-6.5q-0.6-0.7-0.6-1.6t0.6-1.5l3-3q0.6-0.6 1.5-0.6t1.6 0.6l6.5 6.6 6.6-6.6q0.6-0.6 1.5-0.6t1.5 0.6l3.1 3q0.6 0.7 0.6 1.5t-0.6 1.6l-6.6 6.5 6.6 6.6q0.6 0.6 0.6 1.5z"></path>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Helmet>
        <script
          type="text/javascript"
          src="https://static.convaise.com/corona-navi/corona-navi-popup-v1-min.js"
        ></script>
      </Helmet>
    </Layout>
  )
}

export default EmbedTest
